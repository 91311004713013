import React from 'react'
import { Button as HdsButton } from '@postenbring/hedwig-react'
import { Icon } from '../icon/icon'

export const Button = ({href, size, variant, buttonText, buttonTarget, icon }) => {

    return (
        <>  
        <HdsButton asChild variant={variant} size={size} >
            <a href={href} className="hds-mb-24-32">
                {icon && <Icon variant={icon} />}
                {buttonText}
                </a>
        </HdsButton>
        </>
    )
}

