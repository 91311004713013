import React from 'react'
import { Button as HdsButton } from '@postenbring/hedwig-react'
import './button-list.scss'

export const ButtonList = ({ listStyle, buttons}) => {

    const buttonStyles = {
        0: "primary",
        1: "secondary",
        2: "tertiary",
    }

    return (
        <div className={"kp-button-list " + listStyle}>
           
        {buttons.map((button, index) => (
            <HdsButton asChild variant={buttonStyles[index]} fullWidth={listStyle ==="stretched"}>
                <a href={button.href} target={button.target}>{button.linkText}</a>
            </HdsButton>
        ))} 
        </div>
       
    )
}
